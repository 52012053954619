<template>
    <div class="box">
        <div class="mb-3" v-if="$route.query.project_id">
            <router-link to="/projects" class="text-decoration-none">
                <b-button type="is-light" size="is-small" icon-left="backward">
                    Retour à la liste des projets
                </b-button>
            </router-link>
        </div>
        <NlTable :columns.sync="columns" :get-data="loadAsyncData">
            <template #kpis="{ meta }">
                <b-taglist attached>
                    <b-tag type="is-warning" size="is-medium">
                        {{ $t('global.orders') }}
                    </b-tag>
                    <b-tag type="is-dark" size="is-medium">
                        {{ meta.total }}
                    </b-tag>
                </b-taglist>
            </template>

            <template #status="{ props: { row } }">
                <span v-if="row.status === 'todo'" class="tag is-info">À faire</span>
                <span
                    v-else-if="['unassigned', 'unpublished', 'in_progress', 'reviewed'].includes(row.status)"
                    class="tag is-warning"
                >
                    En cours
                </span>
                <span v-else-if="row.status === 'completed'" class="tag is-success">Fait</span>
            </template>

            <template #price_paid="{ props: { row } }"> {{ row.price_paid / 100 }} €</template>

            <template #created_atSearch="{ props }">
                <b-input v-model="props.filters[props.column.field]" size="is-small" type="date" />
            </template>
            <template #published_atSearch="{ props }">
                <b-input v-model="props.filters[props.column.field]" size="is-small" type="date" />
            </template>
        </NlTable>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'MySpotsOrders',
        title: 'Mes commandes de RD',
        data() {
            return {
                columns: [
                    {
                        field: 'id',
                        searchable: false,
                        sortable: false,
                        label: 'ID'
                    },
                    {
                        field: 'status',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Statut'
                    },
                    {
                        field: 'anchor',
                        searchable: false,
                        sortable: false,
                        label: 'Ancre'
                    },
                    {
                        field: 'meta_title',
                        searchable: false,
                        sortable: false,
                        label: 'Meta Title'
                    },
                    {
                        field: 'tag_title',
                        searchable: false,
                        sortable: false,
                        label: 'Tag Title'
                    },
                    {
                        field: 'url_source',
                        searchable: true,
                        sortable: true,
                        label: 'URL'
                    },
                    {
                        field: 'url_target',
                        searchable: false,
                        sortable: false,
                        label: 'Page'
                    },
                    {
                        searchable: false,
                        custom: true,
                        sortable: false,
                        field: 'price_paid',
                        label: 'Prix'
                    },
                    {
                        sortable: true,
                        searchable: true,
                        customSearch: true,
                        field: 'created_at',
                        label: "Date d'ajout",
                        type: 'date',
                        fieldOption: {
                            dateFormat: 'medium_date'
                        }
                    },
                    {
                        sortable: true,
                        searchable: true,
                        customSearch: true,
                        field: 'published_at',
                        label: 'Publication',
                        type: 'date',
                        fieldOption: {
                            dateFormat: 'medium_date'
                        }
                    }
                ]
            }
        },
        methods: {
            ...mapActions('spots', {
                getOrders: 'listMyOrders'
            }),
            loadAsyncData(params) {
                if (this.$route.query.project_id) {
                    params.filter.project_id = this.$route.query.project_id
                }
                return this.getOrders(params)
            }
        }
    }
</script>

<style scoped></style>